import Router from "next/router";
import fixHref from "next-translate/fixHref";
import i18nConfig from "i18n";
import { isSupported, initialLang } from "../helpers/LanguageHelper";
import CommonHelper from "src/utils/helpers/CommonHelper";

/** UDINI WEBSITE **/
export const UDINI_HOME_ROUTE = "/";
export const UDINI_CONTACT_ROUTE = "/contact";
export const UDINI_NEWS_ROUTE = "/news";
export const UDINI_PRODUCTS_SMILE_ROUTE = "/products/smile";
export const UDINI_TERMS_AND_CONDITIONS_ROUTE = "/terms-and-conditions";
export const UDINI_TERMS_OF_USE_ROUTE = "/terms-of-use";

/** SMILE WEB APP **/
export const SMILE_HOME_ROUTE = "/smile";
export const SMILE_SIGNUP_ROUTE = "/smile/signup";
export const SMILE_SIGNUP_SUCCESS_ROUTE = "/smile/signup-success";
export const SMILE_SIGNIN_ROUTE = "/smile/signin";
export const SMILE_PASSWORD_FORGOTTEN_ROUTE = "/smile/password-forgotten";

/** HYBRIDGE WEB APP **/
export const HYBRIDGE_HOME_ROUTE = "/";
export const HYBRIDGE_SIGNUP_ROUTE = "/signup";
export const HYBRIDGE_SIGNUP_SUCCESS_ROUTE = "/signup-success";
export const HYBRIDGE_SIGNIN_ROUTE = "/signin";
export const HYBRIDGE_PASSWORD_FORGOTTEN_ROUTE = "/password-forgotten";

/** AIAAS SERVICES **/
export const AIAAS_ROUTE = "/aiaas";
export const AIAAS_LANDMARKS_DETECTION_ROUTE = "/aiaas/landmarks-detection";
export const AIAAS_PHOTO_ALIGNMENT_ROUTE = "/aiaas/photo-alignment";
export const AIAAS_PHOTO_CLASSIFICATION_ROUTE = "/aiaas/photo-classification";
export const AIAAS_PHOTO_EDITING_ROUTE = "/aiaas/photo-editing";
export const AIAAS_SMILE_DESIGN_GENERATION_ROUTE = "/aiaas/smile-design-generation";
export const AIAAS_TEETH_LABELING_ROUTE = "/aiaas/teeth-labeling";
export const AIAAS_TEETH_SEGMENTATION_ROUTE = "/aiaas/teeth-segmentation";
export const AIAAS_TEXTURE_EXTRACTION_ROUTE = "/aiaas/texture-extraction";
export const AIAAS_2D_SIMULATION_ROUTE = "/aiaas/2d-simulation";
export const AIAAS_CASE_DIFFICULTY_ESTIMATION_FROM_STL_ROUTE = "/aiaas/case-difficulty-estimation-from-stl";
export const AIAAS_2D_SMILE_DESGN_ROUTE = "/aiaas/2d-smile-design";
export const AIAAS_FACE_SCAN_ALIGNMENT_ROUTE = "/aiaas/face-scan-alignment";
export const GENIOVA_HOME_ROUTE = "/geniova";
export const GLOBALD_HOME_ROUTE = "/globalD";
export const STRAUMANN_HOME_ROUTE = "/straumann";
export const C4W_HOME_ROUTE = "/c4w";

export const pushRoute = (url: string, lang: string) => {
  const fixedHref = fixHref(url, lang);
  Router.router?.push(fixedHref);
};

export const rerouteToLang = (lang: string) => {
  const pathname = cleanLangFromPathname(Router.router?.pathname ?? "");
  pushRoute(pathname, lang);
};

export const rerouteIfNeeded = (lang: string) => {
  if (!isSupported(lang)) {
    return;
  }

  const pathname = Router.router?.asPath ?? "";

  if (needsReroute(pathname, lang)) {
    const newPathname = cleanLangFromPathname(pathname);
    pushRoute(newPathname, lang);
  }
};

export const rerouteAtLoginCallback = () => {
  if (Router.router?.query.callback) {
    window.location.href = Router.router?.query.callback as string;
  }
};

export const initLocation = () => {
  if (!CommonHelper.isBrowser()) {
    return;
  }

  const lang = initialLang();
  const pathname = document.location.pathname;

  if (!isSupported(lang) || !needsReroute(pathname, lang)) {
    return;
  }

  let newPathname;

  if (lang === i18nConfig.defaultLanguage) {
    newPathname = cleanLangFromPathname(pathname);
  } else {
    newPathname = `/${lang}${cleanLangFromPathname(pathname)}`;
  }

  if (newPathname === pathname) {
    return;
  }

  document.location.pathname = newPathname;
};

export const cleanLangFromPathname = (pathname: string) => {
  i18nConfig.allLanguages.forEach((l) => {
    pathname = pathname.replace(`/${l}/`, "/");
    if (pathname.slice(-(l.length + 1)) === `/${l}`) {
      pathname = pathname.substr(0, pathname.length - l.length);
    }
  });
  return pathname;
};

const needsReroute = (pathname: string, lang: string) => {
  return !(pathname.includes(`/${lang}/`) || pathname.slice(-(lang.length + 1)) === `/${lang}`);
};
